<template>
    
    <v-container fluid >
            <v-row >
                <v-col>
                    <v-card flat>
                      <v-sheet>
    <v-tabs
      :dark="$store.getters.getColorPalette().isDark"
      :background-color="$store.getters.getColorPalette().backgroundColorCode"
      show-arrows
      v-model="tab"
    >
      <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/shifts'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-clock-time-five-outline</v-icon>{{$store.getters.getTextMap().shifts}}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/sku'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-palette-swatch-variant</v-icon>{{ $store.getters.getTextMap().skus }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/itemCodes'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-palette-swatch-variant</v-icon>{{ $store.getters.getTextMap().item_code }}
      </v-tab>
    </router-link>
      <!-- <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/products'">
      <v-tab  v-if="$store.state.user && $store.getters.getAccessByLevels(['alphaaccess']) ">
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-palette-swatch-variant</v-icon>Products
      </v-tab>
    </router-link> -->
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/location'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-map-marker</v-icon>{{ $store.getters.getTextMap().locations }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/locationEventType'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-move-resize-variant</v-icon>{{ $store.getters.getTextMap().locations_event_types }}
      </v-tab>
    </router-link>
    <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/lists'">
      <v-tab
        
      >
      <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-format-list-bulleted</v-icon>{{ $store.getters.getTextMap().lists }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/equations'">
      <v-tab
        
      >
      <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-plus-minus-variant</v-icon>{{ $store.getters.getTextMap().param_equations }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/comboParams'">
      <v-tab
        
      >
      <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-vector-combine</v-icon>{{ $store.getters.getTextMap().combo_params }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/calculatedParams'">
      <v-tab
        
      >
      <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-calculator-variant-outline</v-icon>{{ $store.getters.getTextMap().calculated_params }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/sheets'">
      <v-tab
      v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess']) "
        >
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-clipboard-text-outline</v-icon>{{ $store.getters.getTextMap().sheets }}
        </v-tab>
      </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/ESGScopeConversion'">
        <v-tab
          v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess']) "
        >
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-pine-tree-variant-outline</v-icon>{{ $store.getters.getTextMap().esg_scope_logging }}
        </v-tab>
      </router-link>
      <!-- <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/ghgEmissions'">
        <v-tab
          v-if="$store.state.user && $store.getters.getAccessByLevels(['logs']) "
        >
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-leaf-maple</v-icon>{{ $store.getters.getTextMap().ghg_scope_conversion }}
        </v-tab>
      </router-link> -->
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/targets'">
        <v-tab
          v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess']) "
        >
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-target</v-icon>{{ $store.getters.getTextMap().targets }}
        </v-tab>
      </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/tariff'">
        <v-tab
          v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess']) "
        >
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-currency-rupee</v-icon>{{ $store.getters.getTextMap().tariffs }}
        </v-tab>
      </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/words'">
      <v-tab
        v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) "
      >
      <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-file-word-box</v-icon>{{ $store.getters.getTextMap().words }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/derived/OEMDevices'">
      <v-tab
        v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','alphaaccess']) "
      >
      <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-fan</v-icon>{{ $store.getters.getTextMap().oem_devices }}
      </v-tab>
    </router-link>
    </v-tabs>
    <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>
  </v-sheet>
  <router-view></router-view>
  </v-card>
                </v-col>
       
            </v-row>         
         <!-- <v-tabs-items v-model="tab" class="">
      <v-tab-item>
        <ShiftsPage/>
      </v-tab-item>
      <v-tab-item>
        <ListsPage/>
      </v-tab-item>
      <v-tab-item>
        <ParamEquationsPage/>
      </v-tab-item>
      <v-tab-item>
        <ComboParamPage/>
      </v-tab-item>
      <v-tab-item>
        <CalculatedParamPage/>
      </v-tab-item>
      <v-tab-item v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) ">
        <SheetsPage/>
      </v-tab-item>
      <v-tab-item v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) ">
        <WordPage/>
      </v-tab-item>
      <v-tab-item v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) ">
        <OEMDevicesPage/>
      </v-tab-item>
         </v-tabs-items> -->
        </v-container>
    
</template>

<script>
// import ShiftsPage from '@/pages/derived/ShiftsPage'
// import ListsPage from '@/pages/derived/ListsPage'
// import ParamEquationsPage from '@/pages/derived/ParamEquationsPage'
// import WordPage from '@/pages/WordPage'
// import ComboParamPage from '@/pages/derived/ComboParamPage'
// import CalculatedParamPage from '@/pages/derived/CalculatedParamPage'
// import SheetsPage from '@/pages/derived/SheetsPage'
// import OEMDevicesPage from '@/pages/OEMDevicesPage'
export default {
    name:'Derived',
    components:{
      // ShiftsPage,
      // ListsPage,
      // ParamEquationsPage,
      // WordPage,
      // ComboParamPage,
      // CalculatedParamPage,
      // SheetsPage,
      // OEMDevicesPage
    },
    data(){
        return {
            show:'',
            tab:null
        }
    },
  //   created() {
  //     this.$router.push('/derived/shifts');
  // },
    mounted() {
      document.title='Derived & computed'
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }else{
      this.$router.push('/derived/shifts');
    }
    },
}
</script>
<style scoped>
.theme--light.v-tabs-items{
  background-color:#263238
}
</style>